
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
  LTooltip,
  LPopup,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default defineComponent({
  name: "CalegMap-Map",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    LTooltip,
    LPopup,
  },
  setup() {
    setCurrentPageTitle("Peta Pergerakan Caleg");

    const store = useStore();
    // const router = useRouter();

    store.dispatch("AllMovementDashboard");

    const center = ref([-6.304159, 106.724997]);

    const zoom = ref(12);

    const iconSize = ref([50, 50]);

    const path = window.location.origin + "/";
    const icon = path + "other/png/advokasii.png";

    // const markers = ref([
    //   {
    //     id: 1,
    //     position: [-6.30219, 106.725977],
    //     icon: path + "other/png/advokasii.png",
    //     data: {
    //       name: "Caleg A",
    //       keterangan: "Relawan Kampanye di",
    //       tanggal: "30 Agustus 2021",
    //       img: path + "other/png/Rectangle -12.png",
    //     },
    //   },
    //   {
    //     id: 2,
    //     position: [-6.303169, 106.724987],
    //     icon: path + "other/png/advokasii-1.png",
    //     data: {
    //       name: "Caleg B",
    //       keterangan: "Relawan Kampanye di",
    //       tanggal: "8 Januari 2021",
    //       img: path + "other/png/Rectangle -12.png",
    //     },
    //   },
    //   {
    //     id: 3,
    //     position: [-6.305169, 106.724987],
    //     icon: path + "other/png/advokasii-2.png",
    //     data: {
    //       name: "Caleg C",
    //       keterangan: "Sosialisasi pemilu di",
    //       tanggal: "9 September 2021",
    //       img: path + "other/png/Rectangle -13.png",
    //     },
    //   },
    // ]);

    const markers = computed(() => store.state.MovementDashboardModule.all);

    // const info = ref([
    //   {
    //     img: path + "other/png/advokasii.png",
    //     text: "Caleg 1",
    //   },
    //   {
    //     img: path + "other/png/advokasii-1.png",
    //     text: "Caleg 2",
    //   },
    //   {
    //     img: path + "other/png/advokasii-2.png",
    //     text: "Caleg 3",
    //   },
    // ]);

    return {
      center,
      markers,
      // info,
      icon,
      zoom,
      iconSize,
    };
  },
});
